body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, "Inter", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'Arial Rounded MT Light';
    src: local('Arial Rounded MT Light'), url(./fonts/Arial_Rounded_MT_Light.ttf) format('truetype');
}
